<template>
  <div>
    <div class="big-box-layout">
      <NavBar
        :class="{ 'nav-color': isSwitchover }"
        :iconColor="'#fff'"
        :bool="true"
        @navCallback="navCallback"
      >
        <div slot="title">钱包</div>
      </NavBar>
      <!-- 余额明细 -->
      <div class="remaining-sum-layout">
        <div class="view-class">
          <div
            :class="{ 'text-size': moneyType * 1 === 1 }"
            @click="remainBtn(1)"
          >
            <span>余额</span>
          </div>
          <div
            :class="{ 'text-size': moneyType * 1 === 2 }"
            @click="remainBtn(2)"
            v-show="false"
          >
            <span>消费金</span>
          </div>
          <div
            :class="{ 'text-size': moneyType * 1 === 3 }"
            @click="remainBtn(3)"
            v-if="levelId * 1 === 5"
          >
            <span>店铺资金</span>
          </div>
        </div>
        <div class="remaining-sum">
          <div
            class="withdrawal-img-box"
            v-if="moneyType * 1 === 1"
            @click="goTuUrl(1)"
          >
            <img
              src="~@/assets/common/yue_ti_xian_icon@2x.png"
              class="withdrawal-img"
            />
            <span class="withdrawal">提现</span>
          </div>
          <div class="money-detail">
            <div class="available">
              <span>可用{{ moneyStr }}</span>
              <!-- <span>余额</span> -->
            </div>
            <div>{{ fourNum(moneyInfo.money) }}</div>
          </div>
          <div class="money-clos-spend">
            <div class="date-of" @click="dateButton">
              <span class="year-month">{{ dateStr }}</span>
              <!-- 下箭头 -->
              <van-icon name="arrow-down" v-if="true" size="17" />
              <!-- 上箭头 -->
              <van-icon name="arrow-up" v-else />
            </div>
            <div class="money-sum">
              <span class="income-money">
                收入:￥{{ fourNum(moneyInfo.income) }}
              </span>
              <span class="spend-money">
                支出:￥{{ fourNum(moneyInfo.expenses) }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <!-- 日期选择弹框 -->
      <van-popup v-model="showDate" round position="bottom">
        <div class="popup-layout">
          <van-datetime-picker
            v-model="currentDate"
            ref="picker"
            type="year-month"
            visible-item-count="5"
            :min-date="minDate"
            :max-date="maxDate"
            :formatter="formatter"
            @confirm="confirmBtn"
            @cancel="cancelBtn"
          >
          </van-datetime-picker>
        </div>
        <!-- <div class="button-operation">
          <van-button type="danger" round @click="confirmBtn">确认</van-button>
          <van-button type="danger" round plain @click="cancelBtn">
            取消
          </van-button>
        </div> -->
      </van-popup>
      <!-- 佣金 -->
      <div class="detail-padding">
        <div class="detail-class-layout">
          <div
            class="detail-class-text"
            :class="{ 'detail-class-dynamic': active == item.water_key }"
            v-for="(item, index) in classList"
            :key="index"
            @click="onClass(item.water_key)"
          >
            <span>{{ item.water_name }} </span>
          </div>
        </div>
        <div class="detail-card">
          <van-list
            v-model="loading"
            :finished="finished"
            offset="20"
            finished-text="没有更多了"
            @load="onLoad"
            v-if="!isBlank"
          >
            <van-cell v-for="(item, i_index) in list" :key="i_index">
              <div class="list-layout">
                <div class="list-left">
                  <div>{{ item.status_str }}</div>
                  <div class="add-money" v-if="item.price * 1 > 0">
                    {{ item.add_reduce * 1 > 0 ? "+" : "-"
                    }}{{ fourNum(item.price) }}
                  </div>
                </div>
                <div class="list-right">
                  <div>{{ item.created_at }}</div>
                  <!-- <div>余额{{ item.remainder }}</div> -->
                </div>
              </div>
            </van-cell>
          </van-list>
          <div class="blank-page" v-if="isBlank">
            <img
              src="~@/assets/blank/background-img-5@2x.png"
              class="blabk-img"
            />
            <span>暂无记录</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/layout/nav-bar/nav-bar.vue";
import { getMoneyDetail } from "@/api/index.js";
import tools from "@/common/js/tools";
import {
  getMoneyStatistics,
  getShopMoneyList,
  getShopMoneyStatistics,
  getShopWaterType,
  getWaterType,
} from "@/api/money";
export default {
  name: "remaining-sum",
  components: {
    NavBar,
  },
  data() {
    return {
      levelId: this.$route.query.levelId || 0,
      // moneyType: this.$route.query.moneyType == undefined  ? 1 : this.$route.query.moneyType,
      moneyType: 1,
      active: 0, // 分类样式显示
      showDate: false,
      minDate: new Date(2015, 0, 1),
      maxDate: new Date(2025, 11, 1),
      currentDate: new Date(),
      classList: ["全部明细", "返佣", "消费", "提现"],
      finished: false, // 是否加载完成
      loading: false, // 是否开启加载
      list: [],
      isSwitchover: false,
      dateStr: "",
      page: 1,
      moneyInfo: {
        expenses: 0,
        income: 0,
        money: 0,
      },
      isAjax: false,
      isBlank: false,
    };
  },
  computed: {
    moneyStr() {
      let str = "余额";
      if (this.moneyType * 1 === 2) {
        str = "消费金";
      } else if (this.moneyType * 1 === 3) {
        str = "店铺资金";
      }
      return str;
    },
  },
  mounted() {
    // console.log(this.levelId);
    this.getMoneyStatistics();
    this.getWaterType();
    addEventListener("scroll", this.scrollToTop);
  },
  methods: {
    navCallback() {
      this.$router.push({ name: "my" });
    },
    goTuUrl(type) {
      switch (type) {
        case 1:
          this.$router.push({
            name: "remain-cash",
            query: { money: this.moneyInfo.money },
          });
          break;

        default:
      }
    },
    floorNum(num) {
      // 获取两位小数
      return tools.twoNum(num);
    },
    fourNum(num) {
      return tools.fourFloat(num);
    },
    async onLoad() {
      this.getWaterList();
    },
    dateButton() {
      this.showDate = true;
    },
    async getMoneyStatistics() {
      if (this.moneyType * 1 >= 3) {
        const res = await getShopMoneyStatistics({
          money_type: 1,
          date: this.dateStr,
        });
        if (res.code * 1 == 1) {
          this.moneyInfo = res.data;
        }
      } else {
        const res = await getMoneyStatistics({
          money_type: this.moneyType,
          date: this.dateStr,
        });
        if (res.code * 1 == 1) {
          this.moneyInfo = res.data;
        }
      }
    },
    async getWaterType() {
      if (this.moneyType * 1 >= 3) {
        const res = await getShopWaterType({
          money_type: 1,
        });
        if (res.code * 1 == 1) {
          this.classList = res.data;
        }
      } else {
        const res = await getWaterType({
          money_type: this.moneyType,
          date: this.dateStr,
          page: this.page,
        });
        if (res.code * 1 == 1) {
          this.classList = res.data;
        }
      }
    },
    async getWaterList() {
      if (this.isAjax) {
        return false;
      }
      this.isAjax = true;
      let res = null;
      if (this.moneyType * 1 < 3) {
        res = await getMoneyDetail({
          money_type: this.moneyType,
          water_key: this.active,
          date: this.dateStr,
          page: this.page,
        });
      } else {
        res = await getShopMoneyList({
          money_type: 1,
          water_key: this.active,
          date: this.dateStr,
          page: this.page,
        });
      }
      if (res === null) {
        return false;
      }

      if (res.data.items.length <= 0) {
        this.isBlank = true;
      } else {
        this.isBlank = false;
      }
      this.list = [...this.list, ...res.data.items];
      if (this.list.length >= res.data.total_num * 1) {
        this.finished = true;
      }
      ++this.page;
      this.loading = false;
      this.isAjax = false;
    },
    // 确认按钮
    confirmBtn() {
      //拿到选中的年月
      let dateStr = this.$refs.picker.getPicker().getValues().join("-");
      dateStr = dateStr.replace("年", "");
      dateStr = dateStr.replace("月", "");
      this.dateStr = dateStr;
      this.showDate = false;
      this.page = 1;
      this.list = [];
      this.getWaterList();
    },
    cancelBtn() {
      this.showDate = false;
    },
    remainBtn(num) {
      // 余额
      this.isBlank = false;
      this.moneyType = num;
      this.getMoneyStatistics();
      this.getWaterType();
      this.onClass(0);
    },
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      }
      return val;
    },
    onClass(index) {
      this.isBlank = false;
      this.active = index;
      this.page = 1;
      this.list.splice(0, this.list.length);
      this.finished = false;
      this.getWaterList();
    },
    scrollToTop() {
      const scrollTop = window.pageYOffset;
      if (scrollTop >= 10) {
        this.isSwitchover = true;
      } else {
        this.isSwitchover = false;
      }
    },
  },
  created() {
    let date = new Date();
    let month = date.getMonth() + 1;
    if (month < 10) {
      month = "0" + month;
    }
    this.dateStr = date.getFullYear() + "-" + month;
  },
};
</script>

<style lang="less" scoped>
.nav-color {
  background: linear-gradient(to right, #fb4e65, #f72821);
}
.big-box-layout {
  height: 100vh;
  width: 100vw;
  background: url("~@/assets/common/background-img-2@2x.png") no-repeat center
    top/100%;
  .nav-layout {
    color: #fff;
  }
}
.remaining-sum-layout {
  padding: 58px 12px 12px 12px;
}
.view-class {
  display: flex;
  align-items: center;
  opacity: 0.9;
  height: 54px;
  font-size: 18px;
  div {
    flex: 1;
    color: #fed6d6;
    text-align: center;
  }
  .text-size {
    font-weight: 500;
    font-size: 21px;
    color: #fff;
  }
}
.remaining-sum {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding: 12px;
  border-radius: 16px;
  background-color: #fff;
  .withdrawal-img-box {
    position: absolute;
    right: 0;
    top: 42px;
    width: 100px;
    .withdrawal-img {
      width: 100%;
      display: block;
    }
    .withdrawal {
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(90%, -50%);
      color: #e61f18;
      font-size: 18px;
    }
  }
  .money-detail {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 30px;
    font-weight: 600;
    padding: 20px 0 40px 0;
    .available {
      font-size: 18px;
      color: #6f6f6f;
      font-weight: normal;
    }
  }
  .money-clos-spend {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .date-of {
      display: flex;
      align-items: center;
      font-size: 17px;
      .year-month {
        margin-right: 10px;
      }
    }
    .money-sum {
      color: #fd6d1f;
      font-size: 14px;
      .income-money {
        margin-right: 10px;
      }
    }
  }
}
// 弹框样式
.popup-layout {
  padding: 12px;
}
.button-operation {
  width: 100%;
  display: flex;
  justify-content: space-around;
  box-sizing: border-box;
}
.van-button {
  width: 90px;
  height: 40px;
}
// 价格明样式
.detail-padding {
  padding: 0 12px 12px 12px;
  .no-record {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    height: 334px;
    background-color: #fff;
    border-radius: 16px;
    margin-top: 12px;
  }
}
.detail-class-layout {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  .detail-class-text {
    flex-shrink: 0;
    flex-shrink: 0;
    text-align: center;
    padding: 4px 10px;
    min-width: 80px;
    font-size: 17px;
    color: #6f6f6f;
  }
  .detail-class-dynamic {
    // padding: 10px 0;
    min-width: 80px;
    text-align: center;
    border-radius: 999px;
    background-color: #ed301d;
    color: #fff;
    transition: all 0.3s;
  }
}
// 列表
.detail-card {
  position: relative;
  min-height: 45vh;
  padding: 12px;
  background-color: #fff;
  border-radius: 16px;
  .blank-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 53px;
    left: 50%;
    transform: translateX(-50%);
    .blabk-img {
      width: 234px;
      height: 155px;
    }
    span {
      margin-top: 13px;
      font-size: 18px;
      color: #aaaaaa;
    }
  }
}
.van-cell {
  padding: 12px;
}
.list-layout {
  .list-left {
    display: flex;
    justify-content: space-between;
    font-size: 17px;
    .add-money {
      color: #e61f18;
    }
  }
  .list-right {
    display: flex;
    justify-content: space-between;
    color: #aaaaaa;
    font-size: 13px;
  }
}
</style>
